import { createSlice } from "@reduxjs/toolkit";

export const messageQuoteSlice = createSlice({
  name: "messageQuote",
  initialState: {
    id: "",
    name: "",
    date: "",
    msg: "",
    profileImage: "",
  },
  reducers: {
    setQuoteMsg: (state, action) => {
      const { name, date, msg, profileImage, id } = action.payload;
      state.name = name;
      state.date = date;
      state.msg = msg;
      state.profileImage = profileImage;
      state.id = id;
    },
    clearQuoteMsg: (state) => {
      console.log("buraya girdi");
      state.name = "";
      state.date = "";
      state.msg = "";
      state.profileImage = "";
      state.id = "";
    },
  },
});

export const { setQuoteMsg, clearQuoteMsg } = messageQuoteSlice.actions;
export const selectQuotedMessage = (state) => state.messageQuote;
export default messageQuoteSlice.reducer;
