import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
  name: "menu",
  initialState: {
    active: false, // `active` burada state'in doğrudan bir parçası
    activeChannels: false,
  },
  reducers: {
    setMenu: (state, action) => {
      console.log("setMenu");
      console.log(action);
      state.active = action.payload.active; // Doğru property `active`
      state.activeChannels = action.payload.activeChannels;
    },
    toggleMenu: (state) => {
      state.active = !state.active; // Menü durumunu tersine çeviren ek bir fonksiyon
    },
  },
});

export const { setMenu, toggleMenu } = menuSlice.actions;
export const selectMenu = (state) => state.menu.active;
export const selectChannelsMenu = (state) => state.menu.activeChannels;
export default menuSlice.reducer;
