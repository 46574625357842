import { createSlice } from "@reduxjs/toolkit";

export const messageDelSlice = createSlice({
  name: "messageDel",
  initialState: {
    delmesId: "0",
  },
  reducers: {
    setdelMsg: (state, action) => {
      state.delmesId = action.payload;
    },
  },
});
export const { setdelMsg } = messageDelSlice.actions;
export const selectmesDel = (state) => state.messageDel.delmesId;
export default messageDelSlice.reducer;
