import url from "./url";
const apiRequest = async (endpoint, method = "GET", body = null) => {
    const requestOptions = {
        method,
        withCredentials: true,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        ...(body && { body: JSON.stringify(body) }),
    };


    try {
        const response = await fetch(`${url.server}${endpoint}`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.error || "Something went wrong");
        }
        return data;
    } catch (error) {
        console.error("API request failed:", error);
        window.location.reload();        
    }
};

export default apiRequest;