import React, { useState } from "react";

export default function ImojiButton({ onEmojiSelect, id }) {
  const emojiArray = [
    "😀",
    "😁",
    "😂",
    "🤣",
    "😃",
    "😄",
    "😅",
    "😆",
    "😉",
    "😊",
    "😋",
    "😎",
    "😍",
    "😘",
    "🥰",
    "😗",
    "😙",
    "😚",
    "🙂",
    "🤩",
    "🤔",
    "🤨",
    "😐",
    "😑",
    "😶",
    "🙄",
    "😏",
    "😣",
    "😥",
    "😮",
    "🤐",
    "😯",
    "😯",
    "😫",
    "🥱",
    "😴",
    "😌",
    "😛",
    "😜",
    "😝",
    "🤤",
    "😒",
    "😓",
    "😔",
    "😕",
    "🙃",
    "🤑",
    "😲",
    "🙁",
    "😖",
    "😞",
    "😤",
    "🤯",
  ];

  const [showPopup, setShowPopup] = useState(false);

  function togglePopup(e) {
    e.preventDefault(); // Bu satır olayın yayılmasını durdurur
    setShowPopup(!showPopup);
  }

  function handleEmojiClick(e, emoji) {
    e.preventDefault(); // Bu satır olayın yayılmasını durdurur
    onEmojiSelect(emoji);
    setShowPopup(false);
  }

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <button
        onClick={togglePopup}
        style={{
          fontSize: "24px",
          cursor: "pointer",
          background: "none",
          border: "none",
        }}
      >
        😀
      </button>

      {showPopup && (
        <div
          className="emoji-popup"
          id={id}
          style={{
            position: "absolute",
            bottom: "100%",
            right: 0,
            border: "1px solid #ccc",
            background: "white",
            width: "200px",
            height: "200px",
            overflowY: "scroll",
            display: "flex",
            flexWrap: "wrap",
            padding: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
          }}
          onClick={(e) => e.preventDefault()} // Popup içindeki tıklamaları ana popup'a yayılmasını engelle
        >
          {emojiArray.map((emoji, index) => (
            <span
              key={index}
              onClick={(e) => handleEmojiClick(e, emoji)}
              style={{ cursor: "pointer", fontSize: "24px", margin: "5px" }}
            >
              {emoji}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

// import React, { useState } from "react";

// export default function ImojiButton({ onEmojiSelect, id }) {
//   var emojiArray = [
//     "😀",
//     "😁",
//     "😂",
//     "🤣",
//     "😃",
//     "😄",
//     "😅",
//     "😆",
//     "😉",
//     "😊",
//     "😋",
//     "😎",
//     "😍",
//     "😘",
//     "🥰",
//     "😗",
//     "😙",
//     "😚",
//     "🙂",
//     "🤩",
//     "🤔",
//     "🤨",
//     "😐",
//     "😑",
//     "😶",
//     "🙄",
//     "😏",
//     "😣",
//     "😥",
//     "😮",
//     "🤐",
//     "😯",
//     "😯",
//     "😫",
//     "🥱",
//     "😴",
//     "😌",
//     "😛",
//     "😜",
//     "😝",
//     "🤤",
//     "😒",
//     "😓",
//     "😔",
//     "😕",
//     "🙃",
//     "🤑",
//     "😲",
//     "🙁",
//     "😖",
//     "😞",
//     "😤",
//     "🤯",
//   ];
//   // var [index, setIndex] = useState(0)
//   // var [emoji, setEmoji] = useState(emojiArray[index])
//   // function MouseOver(event) {
//   //     if (index === emojiArray.length - 1){
//   //         index = 0;
//   //     }else{
//   //         index +=1;
//   //     }
//   //     setIndex(index);
//   //     setEmoji(emojiArray[index]);
//   //     event.target.innerText = emoji;
//   //     event.target.style.filter =	"grayscale(0)";
//   // }
//   // function MouseOut(event){
//   //     event.target.style.filter =	"grayscale(100%)"
//   // }
//   const [showPopup, setShowPopup] = useState(false);

//   function togglePopup() {
//     setShowPopup(!showPopup);
//   }

//   function handleEmojiClick(emoji) {
//     onEmojiSelect(emoji);
//     setShowPopup(false);
//   }

//   return (
//     <div style={{ position: "relative", display: "inline-block" }}>
//       {/* Button with Smiley Face Emoji */}
//       <button
//         onClick={togglePopup}
//         style={{
//           fontSize: "24px",
//           cursor: "pointer",
//           background: "none",
//           border: "none",
//         }}
//       >
//         😀
//       </button>

//       {/* Popup with Emoji List */}
//       {showPopup && (
//         <div
//           className="emoji-popup"
//           id={id}
//           style={{
//             position: "absolute",
//             bottom: "100%", // Popup opens upwards
//             // left: "50%",
//             // transform: "translateX(-50%)",
//             right: 0,
//             border: "1px solid #ccc",
//             background: "white",
//             width: "200px", // Square shape
//             height: "200px",
//             overflowY: "scroll",
//             display: "flex",
//             flexWrap: "wrap",
//             padding: "10px",
//             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//             borderRadius: "10px",
//           }}
//         >
//           {emojiArray.map((emoji, index) => (
//             <span
//               key={index}
//               onClick={() => handleEmojiClick(emoji)}
//               style={{ cursor: "pointer", fontSize: "24px", margin: "5px" }}
//             >
//               {emoji}
//             </span>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// }
