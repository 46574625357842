import React, { useEffect, useState } from "react";
import SBtn from "./SBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { selectUser } from "../features/userSlice";
import {
  selectServer,
  updateServer,
  setServer,
  selectServerUpdate,
} from "../features/serverSlice";
import { useSelector, useDispatch } from "react-redux";
//import url from "../url.json";
import { selectChannelsMenu, setMenu } from "../features/mobileSlice";

import url from "../features/url";
import ImojiButton from "./ImojiButton";
export default function ServerList(props) {
  const dispatch = useDispatch();
  let currentMenuState = useSelector(selectChannelsMenu);
  let [serverName, setServerName] = React.useState("");
  let [server, setServerL] = React.useState([]);
  let [show, setShow] = React.useState(false);
  let [serverShow, setServerShow] = React.useState(false);
  let [newName, setNewName] = React.useState("");
  let [newServer, setNewServer] = React.useState("");
  let currentUser = useSelector(selectUser);
  let [mount, setMount] = React.useState(false);
  var socket = props.socket;
  const user = useSelector(selectUser);
  let currentServer = useSelector(selectServer);
  let currentServerUpdate = useSelector(selectServerUpdate);
  const [showModal, setShowModal] = useState(false);
  let [controlList, setControlList] = useState("");
  let [serverProfile, setserverProfile] = useState("");

  useEffect(() => {
    socket.emit("join-room", "serverlist");
    socket.on("server-created", (msg) => {
      if (msg !== controlList) {
        console.log("GetServerList");
        GetServerList();
        setControlList(msg);
      }
    });
  }, []);

  function setCurrentServer(s) {
    if (s !== undefined) {
      console.log("SetCurrentServer");
      console.log(s);
      dispatch(
        setServer({
          serverName: s.serverName,
          serverId: s._id,
          serverProfile: s.ServerProfile,
        })
      );

      localStorage.setItem("serverCurrent", s._id);
    }
  }
  useEffect(() => {
    console.log("currentServer");
    console.log(currentServer);
    if (currentServer.server.serverName !== serverName) {
      // GetServerList();
      setServerName(currentServer.server.serverName);
      localStorage.setItem("serverCurrent", currentServer.server.serverId);
    }
    // if (currentServer.server.serverProfile !== serverProfile) {
    //   // GetServerList();
    //   setserverProfile(currentServer.server.serverProfile);
    //   localStorage.setItem("serverCurrent", currentServer.server.serverId);
    // }
  }, [currentServer.server.serverName]);
  useEffect(() => {
    console.log("currentServer");
    console.log(currentServer.server);
    if (currentServer.server.updateServer !== serverProfile) {
      console.log("currentServer------------- burya girdi");
      console.log(serverProfile);
      GetServerList();
      setserverProfile(currentServer.server.serverProfile);
      localStorage.setItem("serverCurrent", currentServer.server.serverId);
    }
  }, [currentServerUpdate]);
  useEffect(() => {
    socket.emit("authenticate", user.id);
    setNewName(user.name);
  }, []);
  function GetServerList() {
    console.log("buraya girdi");
    fetch(`${url.server}api/get/server`, {
      method: "POST",
      credentials: "include",
      withCredentials: true,
      headers: {
        //"Access-Control-Allow-Origin": url.frontend,
        // "Access-Control-Allow-Credentials": "true",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: user.id }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log("res.servers");
        console.log(res.servers);
        if (res.error === "null") {
          setServerL(res.servers);
        }
        const storedServer = localStorage.getItem("serverCurrent");
        const validServers = res.servers.filter(
          (server) => server && server._id !== null
        );
        if (res.error === "null") {
          setServerL(validServers);
        }
        console.log("res.servers");
        console.log(validServers);
        if (storedServer && storedServer !== "undefined") {
          const serverIndex = validServers.findIndex(
            (server) => server && server._id === storedServer
          );

          if (serverIndex !== -1) {
            // Eğer storedServer bulunursa, onun indeksini ayarla
            console.log("server bulundu, indeksi ayarlanıyor:", serverIndex);
            setCurrentServer(validServers[serverIndex]);
          } else {
            // Eğer storedServer bulunamazsa, son sunucunun indeksini ayarla
            console.log("server bulunamadı, son sunucu ayarlanıyor.");
            console.log(validServers[validServers.length - 1]);
            setCurrentServer(validServers[validServers.length - 1]);
          }
        } else {
          console.log("server hiiiireeee2");
          console.log(validServers[validServers.length - 1]);
          setCurrentServer(validServers[validServers.length - 1]);
        }
      });
  }
  React.useEffect(() => {
    socket.on("member-joined", (id, u) => {
      if (user.id === u) {
        GetServerList();
      }
    });
  });

  useEffect(() => {
    server.map((s) => {
      if (s) socket.emit("server-connected", s._id);
    });
    console.log("server");
    console.log(server);
  }, [server]);
  // function createServer() {
  //   let name = prompt("server name");
  //   fetch(`${url.server}api/register/server`, {
  //     method: "POST",
  //     credentials: "include",
  //     withCredentials: true,
  //     headers: {
  //       //"Access-Control-Allow-Origin": url.frontend,
  //       //"Access-Control-Allow-Credentials": "true",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ name: name, admin: user.id }),
  //   })
  //     .then((response) => response.json())
  //     .then((res) => {
  //       if (res.status === "done") {
  //         GetServerList();
  //       }
  //     });
  // }

  const [name, setName] = useState("");

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (newServer) {
      var data = new FormData();
      data.append("image", e.target.file.files[0]);
      data.append("name", newServer);
      data.append("admin", currentUser.id);
      fetch(`${url.server}api/register/server`, {
        method: "POST",
        credentials: "include",
        body: data,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === "done") {
            socket.emit(
              "server-created",
              "serverlist-" + newServer + "-create"
            );
            GetServerList();
            setNewServer("");
          }
        });
    }
    setShowModal(false); // Submitten sonra modalı kapat
  };

  useEffect(() => {
    GetServerList();
  }, []);
  useEffect(() => {
    console.log("showModal");
    console.log(showModal);
  }, [showModal]);

  async function handleLogout() {
    await fetch(`${url.server}auth/logout`, {
      method: "POST",
      credentials: "include",
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": url.frontend,
        "Access-Control-Allow-Credentials": "true",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error("Logout failed:", data.error);
        } else {
          console.log(data.message);
          window.location.href = "/";
          localStorage.removeItem("user");
        }
      })
      .catch((error) => {
        console.error("There was an error logging out:", error);
      });
  }

  function changepreview(e) {
    if (e.target.files[0]) {
      document.querySelector(".preview").src = URL.createObjectURL(
        e.target.files[0]
      );
    }
  }
  const handleDrop = (e) => {
    e.preventDefault();
    e.dataTransfer.effectAllowed = "move";
    let files = e.dataTransfer.files;
    if (
      files[0].type === "image/jpeg" ||
      files[0].type === "image/png" ||
      files[0].type === "image/gif"
    ) {
      document.querySelector("#file").files = files;
      document.querySelector(".preview").src = URL.createObjectURL(files[0]);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    // if (e.target.file.files[0]) {
    if (show) {
      var data = new FormData();
      // data.append("image", e.target.file.files[0]);
      data.append("id", user.id);
      data.append("name", newName);
      fetch(`${url.server}api/update/userdata`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": url.frontend,
          "Access-Control-Allow-Credentials": "true",
          // 'Content-Type': 'multipart/form-data'
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: data,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === "Uploaded") {
            setShow(false);
          }
        });
    } else if (serverShow) {
      var data = new FormData();
      data.append("image", e.target.file.files[0]);
      data.append("serverId", currentServer.server.serverId);
      data.append("serverName", newName);
      console.log(data);
      fetch(`${url.server}api/update/server`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": url.frontend,
          "Access-Control-Allow-Credentials": "true",
        },
        body: data,
      })
        .then((response) => response.json())
        .then((res) => {
          //   if (res.status === "Uploaded") {
          if (res.status === "Server name updated") {
            setServerShow(false);
            setMount(false);
            setNewName("");
            console.log("currentServer for update => ", currentServer);
            socket.emit(
              "server-created",
              "serverlist-" + currentServer.server.serverId + "-update"
            );
            if (currentServer.server !== undefined) {
              const updatedServer = {
                ...currentServer,
                ...currentServer.server,
                serverName: newName, // Yeni sunucu adını burada ayarlayın
                serverProfile: "serverProfile/" + newName, // Yeni profil URL'sini burada ayarlayın
              };
              dispatch(updateServer(updatedServer));
            }
          }
        });
    }
    // }
  }
  const handleMenuToggle = async () => {
    console.log("currentMenuState");
    console.log(currentMenuState);
    dispatch(setMenu({ activeChannels: !currentMenuState }));
    // Menü aktif ise kapat, kapalı ise aç.
    // dispatch(toggleMenu());
  };
  const handleEmojiSelect = (emoji) => {
    setNewServer((prevMessage) => prevMessage + emoji);
  };
  return (
    <>
      <div className="server-list-containers">
        <div style={{ paddingTop: "10px" }}>
          <button
            aria-label="toggle-menu"
            className="btn mobile-menu"
            style={{
              marginLeft: "auto",
              backgroundColor: "transparent",
              color: "green",
              border: "none",
              fontSize: "16px",
            }}
            onClick={() => handleMenuToggle()}
          >
            <i
              className="bi bi-arrow-left-right"
              style={{
                fontWeight: "bold", // Kalın font
                textShadow: "1px 0 0 currentColor, -1px 0 0 currentColor",
              }}
            ></i>{" "}
            {/* Bootstrap ikonu */}
          </button>
        </div>
        <div className="server-list">
          {server.map((servers) =>
            servers ? (
              <SBtn
                key={servers._id}
                uid={servers._id}
                profile={servers.ServerProfile}
                name={servers.serverName}
              ></SBtn>
            ) : (
              ""
            )
          )}
          <div className="details mobile">
            {/* <img
            src={url.server + currentUser.profile}
            onClick={() => setShow(!show)}
          ></img> */}
            <div
              className="profile-avatar"
              style={{
                backgroundColor: currentUser.profile
                  ? currentUser.profile
                  : "#9a9c9e",
              }}
            >
              {currentUser.name
                ? currentUser.name.charAt(0).toUpperCase()
                : "X"}
            </div>
            {/* <div className="personal-details-text">
            <h4>{currentUser.name}</h4>
            <h5>{`#${currentUser.id.substr(1, 4)}`}</h5>
            </div> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                margin: "6% ",
                marginRight: "9%",
                marginTop: "8%",
                cursor: "pointer",
                fill: "white",
                height: "16px",
              }}
              className="create-channel"
              onClick={() => setShow(!show)}
              viewBox="0 0 512 512"
            >
              <path d="M495.9 166.6C499.2 175.2 496.4 184.9 489.6 191.2L446.3 230.6C447.4 238.9 448 247.4 448 256C448 264.6 447.4 273.1 446.3 281.4L489.6 320.8C496.4 327.1 499.2 336.8 495.9 345.4C491.5 357.3 486.2 368.8 480.2 379.7L475.5 387.8C468.9 398.8 461.5 409.2 453.4 419.1C447.4 426.2 437.7 428.7 428.9 425.9L373.2 408.1C359.8 418.4 344.1 427 329.2 433.6L316.7 490.7C314.7 499.7 307.7 506.1 298.5 508.5C284.7 510.8 270.5 512 255.1 512C241.5 512 227.3 510.8 213.5 508.5C204.3 506.1 197.3 499.7 195.3 490.7L182.8 433.6C167 427 152.2 418.4 138.8 408.1L83.14 425.9C74.3 428.7 64.55 426.2 58.63 419.1C50.52 409.2 43.12 398.8 36.52 387.8L31.84 379.7C25.77 368.8 20.49 357.3 16.06 345.4C12.82 336.8 15.55 327.1 22.41 320.8L65.67 281.4C64.57 273.1 64 264.6 64 256C64 247.4 64.57 238.9 65.67 230.6L22.41 191.2C15.55 184.9 12.82 175.3 16.06 166.6C20.49 154.7 25.78 143.2 31.84 132.3L36.51 124.2C43.12 113.2 50.52 102.8 58.63 92.95C64.55 85.8 74.3 83.32 83.14 86.14L138.8 103.9C152.2 93.56 167 84.96 182.8 78.43L195.3 21.33C197.3 12.25 204.3 5.04 213.5 3.51C227.3 1.201 241.5 0 256 0C270.5 0 284.7 1.201 298.5 3.51C307.7 5.04 314.7 12.25 316.7 21.33L329.2 78.43C344.1 84.96 359.8 93.56 373.2 103.9L428.9 86.14C437.7 83.32 447.4 85.8 453.4 92.95C461.5 102.8 468.9 113.2 475.5 124.2L480.2 132.3C486.2 143.2 491.5 154.7 495.9 166.6V166.6zM256 336C300.2 336 336 300.2 336 255.1C336 211.8 300.2 175.1 256 175.1C211.8 175.1 176 211.8 176 255.1C176 300.2 211.8 336 256 336z" />
            </svg>

            <svg
              onClick={() => handleLogout()}
              className="svg-icon"
              style={{
                margin: "6% ",
                marginRight: "9%",
                marginTop: "8%",
                cursor: "pointer",
                fill: "white",
                height: "16px",
              }}
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M768 106V184c97.2 76 160 194.8 160 328 0 229.6-186.4 416-416 416S96 741.6 96 512c0-133.2 62.8-251.6 160-328V106C121.6 190.8 32 341.2 32 512c0 265.2 214.8 480 480 480s480-214.8 480-480c0-170.8-89.6-321.2-224-406z"
                fill=""
              />
              <path
                d="M512 32c-17.6 0-32 14.4-32 32v448c0 17.6 14.4 32 32 32s32-14.4 32-32V64c0-17.6-14.4-32-32-32z"
                fill=""
              />
            </svg>
          </div>
          {show || serverShow ? (
            <div className="modal">
              <form onSubmit={(e) => handleSubmit(e)}>
                {/* <div
                  className="section1"
                  onDrop={(event) => handleDrop(event)}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.dataTransfer.effectAllowed = "move";
                  }}
                  onDragEnter={(e) => e.preventDefault()}
                >
                  <span
                    className="profile"
                    onDrop={(event) => handleDrop(event)}
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnter={(e) => e.preventDefault()}
                  >
                    <img
                      src={
                        show
                          ? url.server + currentUser.profile
                          : url.server + currentServer.server.serverProfile
                      }
                      className="preview"
                      onDrop={(event) => handleDrop(event)}
                      onDragOver={(e) => e.preventDefault()}
                      onDragEnter={(e) => e.preventDefault()}
                    />
                    <input
                      type="file"
                      id="file"
                      name="file"
                      className="file ab"
                      accept=".gif, .jpeg, .gif, .jpg"
                      onChange={(e) => changepreview(e)}
                    />
                    <label htmlFor="file" className="ab">
                      change Profile picture
                    </label>
                    <h5>Drag and drop works</h5>
                  </span>
                </div> */}
                <div className="settings-input-btns">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={newName}
                    className="cinput"
                    placeholder="Enter name"
                    maxLength={50}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                </div>
                <div className="btn-group">
                  <button
                    className="btn login noselect"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setShow(false);
                      setServerShow(false);
                      setMount(false);
                    }}
                  >
                    CANCEL
                  </button>
                  <button className="btn login noselect" type="submit">
                    SAVE
                  </button>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}
          {showModal && (
            <div className="modal">
              <form onSubmit={handleSubmitForm}>
                <div
                  className="section1"
                  onDrop={(event) => handleDrop(event)}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.dataTransfer.effectAllowed = "move";
                  }}
                  onDragEnter={(e) => e.preventDefault()}
                >
                  <span
                    className="profile"
                    onDrop={(event) => handleDrop(event)}
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnter={(e) => e.preventDefault()}
                  >
                    <img
                      src={
                        show
                          ? url.server + currentUser.profile
                          : url.server + currentServer.server.serverProfile
                      }
                      className="preview"
                      onDrop={(event) => handleDrop(event)}
                      onDragOver={(e) => e.preventDefault()}
                      onDragEnter={(e) => e.preventDefault()}
                    />
                    <input
                      type="file"
                      id="file"
                      name="file"
                      className="file ab"
                      accept=".gif, .jpeg, .gif, .jpg"
                      onChange={(e) => changepreview(e)}
                    />
                    <label htmlFor="file" className="ab">
                      change Profile picture
                    </label>
                    <h5 style={{ color: "white" }}>Drag and drop works</h5>
                  </span>
                </div>
                <div className="settings-input-btns">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    style={{ zIndex: "1" }}
                    placeholder="Enter server name"
                    maxLength={50}
                    value={newServer}
                    onChange={(e) => setNewServer(e.target.value)}
                  />

                  <ImojiButton
                    onEmojiSelect={handleEmojiSelect}
                    id="settings2"
                  />
                </div>
                <div className="btn-group">
                  <button
                    className="btn login noselect"
                    type="button"
                    onClick={() => setShowModal(false)} // Cancel butonuna basınca modalı kapatır
                  >
                    CANCEL
                  </button>
                  <button className="btn login noselect" type="submit">
                    SAVE
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
        {currentUser.package === "power" ? (
          // <div onClick={() => setShowModal(true)}>
          //   <div className="circle-btn" data-name="Yeni Server Oluştur">
          //     <FontAwesomeIcon icon={faPlus} color="green" />
          //   </div>
          // </div>
          <div onClick={() => setShowModal(true)}>
            <div className="circle-btn" data-name="Yeni Server Oluştur">
              <FontAwesomeIcon icon={faPlus} color="green" />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
