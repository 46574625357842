import React, { useEffect } from "react";
import { setServer } from "../features/serverSlice";
import { selectServer } from "../features/serverSlice";
import { useSelector, useDispatch } from "react-redux";
//import url from "../url.json";
import url from "../features/url";
import { clearQuoteMsg } from "../features/messageReplySlice";

export default function SBtn(props) {
  let currentServer = useSelector(selectServer);
  const dispatch = useDispatch();
  let profile = props.profile;
  let name = props.name;
  let id = props.uid;
  function setCurrentServer(name, id) {
    console.log("setCurrentServer sbtn");
    dispatch(
      setServer({
        serverName: name,
        serverId: id,
        serverProfile: profile,
        updateServer: profile,
      })
    );
    dispatch(clearQuoteMsg());
  }
  return (
    <>
      <div
        onClick={() => {
          setCurrentServer(name, id);
        }}
      >
        <div
          className={
            id === currentServer.server.serverId
              ? "active-server circle-btn square-btn circle_"
              : "circle-btn circle_"
          }
          data-name={name}
        >
          {profile ? (
            <img
              src={url.server + profile}
              alt={name}
              className="server-image"
            />
          ) : (
            <div className="initials-avatar">
              {name ? name.charAt(0).toUpperCase() : "X"}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
