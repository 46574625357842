import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import serverReducer from "../features/serverSlice";
import channelReducer from "../features/channelSlice";
import activeChatReducer from "../features/activeChatSlice";
import menuSliceReducer from "../features/mobileSlice";
import messageDelSlice from "../features/messageDelSlice";
import messageQuoteSlice from "../features/messageReplySlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    server: serverReducer,
    channel: channelReducer,
    active: activeChatReducer,
    menu: menuSliceReducer,
    messageDel: messageDelSlice,
    messageQuote: messageQuoteSlice,
  },
});
