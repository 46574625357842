import { createSlice } from "@reduxjs/toolkit";

export const serverSlice = createSlice({
  name: "server",
  initialState: {
    serverName: null,
    serverId: null,
    serverProfile: null,
    server: { name: "loading" },
    updateServer: null,
  },
  reducers: {
    setServer: (state, action) => {
      state.server = action.payload;
    },
    setServerUpdate: (state, action) => {
      state.updateServer = action.payload.updateServer;
    },
    updateServer: (state, action) => {
      console.log("state.server");
      console.log(state.server);
      if (action.payload.serverName) {
        state.serverName = action.payload.serverName;
      }
      if (action.payload.serverProfile) {
        state.serverProfile = action.payload.serverProfile;
      }
      if (action.payload.updateServer) {
        state.updateServer = action.payload.updateServer;
      }

      state.server = {
        ...state.server,
        ...action.payload,
      };
    },
  },
});
export const { setServer, updateServer, setServerUpdate } = serverSlice.actions;
export const selectServer = (state) => state.server;
export const selectServerUpdate = (state) => state.server.updateServer;
export default serverSlice.reducer;
