import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAward, faFile} from "@fortawesome/free-solid-svg-icons";
//import url from "../url.json";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../features/userSlice";
import { selectChannel } from "../features/channelSlice";
import { selectServer } from "../features/serverSlice";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import url from "../features/url";
import { setdelMsg } from "../features/messageDelSlice";
import { setQuoteMsg } from "../features/messageReplySlice";

export default function ChatDisplay(props) {
  let name = props.from;
  let senderId = props.senderId;
  let profileImage = props.profileImage;
  let banned = props.banned
  let currentUser = useSelector(selectUser);
  let currentChannel = useSelector(selectChannel);
  let currentServer = useSelector(selectServer);
  const dispatch = useDispatch();
  function fornattedDate(dateString) {
    //return dateString;
    const date = new Date(dateString);
    return date.toLocaleString("tr-TR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      //second: '2-digit',
      hour12: false,
    });
  }
  var socket = props.socket;
  let date = fornattedDate(props.date);
  const [processModalChat, setProcessModalChat] = useState(false);
  let previous = props.lastmessage.id;
  let msgId = props.id_;
  let previousDate = props.lastmessage.date;
  let type = props.type;
  let filedata = props.filedata;
  let repliedMess = props.repliedMess;
  var fileExt = filedata ? filedata.name.split(".").pop() : "";
  const acceptable = ["jpg", "jpeg", "png", "gif", "ico", "bmp", "apng"];
  let displayable = acceptable.includes(fileExt.toLowerCase());
  previousDate = new Date(previousDate).toLocaleDateString();
  let current = props.current;
  let a = 1;
  if (previous === current) a = 0;

  console.log(name + ' => ' + banned)

  const handleDeleteMSGToggle = async () => {
    let response;
    console.log("msgId");
    console.log(msgId);
    try {
      // Dispatch message deletion action if msgId contains "msglist"
      // if (msgId.includes("msglist")) {
      dispatch(setdelMsg(msgId));
      // }

      // Make the delete request
      response = await fetch(`${url.server}api/delete/chat`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: msgId, user_id: currentUser.id }),
      });

      // Check if the response is successful
      if (response.ok) {
        // Parse response JSON
        const data = await response.json();

        // Access the chat ID from the response data
        if ((data.status.body.status = "ok" && data.status.body.id)) {
          console.log("Deleted chat ID:", data.status.body.id);

          // Emit delete message event with channel and message ID
          const payload = {
            channelId: currentChannel.channel.channelId,
            msgId: data.status.body.id,
          };

          socket.emit("delete-msg", payload);

          // Close the modal
          setProcessModalChat(false);
        } else if (data.error) {
          console.error("Error:", data.error);
        }
      } else {
        console.error("Failed to delete chat. Status:", response.status);
      }
    } catch (error) {
      console.error("Error toggling delete:", error);
    }
  };

  const setBlockUserModalChat = async () => {
    try {
      fetch(`${url.server}api/update/banned-user`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          //    'Access-Control-Allow-Origin': url.frontend,
          //    'Access-Control-Allow-Credentials': 'true',
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: senderId, userid: currentUser.id }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === "Uploaded") {
            setProcessModalChat(false);
          }
        });
    } catch (error) {
      console.error("Error toggling lock:", error);
    }
  };

  const setUnBlockUserModalChat = async () => {
    try {
      fetch(`${url.server}api/update/un-banned-user`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          //    'Access-Control-Allow-Origin': url.frontend,
          //    'Access-Control-Allow-Credentials': 'true',
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: senderId, userid: currentUser.id }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === "Uploaded") {
            setProcessModalChat(false);
          }
        });
    } catch (error) {
      console.error("Error toggling lock:", error);
    }
  };

  const setRepliedMessage = async () => {
    console.log("Setting reply message");
    const replyPayload = {
      name: props.from,
      date: props.date,
      msg: props.msg,
      profilePhoto: props.profileImage,
      id: props.id_,
    };

    dispatch(setQuoteMsg(replyPayload));
  };
  const handleAnchor = (id_) => {
    console.log("Setting anchor");
    console.log(id_);
    const element = document.getElementById(id_);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <>
      <div className="chat-group" id={msgId}>
        {" "}
        {repliedMess && repliedMess.msg && (
          <div
            className={"chat chat-replied"}
            onClick={() => handleAnchor(repliedMess.id)}
          >
            <div
              className="profile"
              style={{
                backgroundColor: "#9a9c9e",
              }}
            >
              {repliedMess.name
                ? repliedMess.name.charAt(0).toUpperCase()
                : "X"}
            </div>
            <div className="msg">
              <h4>


                <span style={{ textDecoration: banned ? 'line-through' : 'none' }}>
                    @{repliedMess.name}{" "}
                  </span>

                <span className="noselect" style={{fontSize: '80%'}}>&nbsp; {fornattedDate(repliedMess.date)}
                </span>
              </h4>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5
                  style={{
                    maxWidth: "270px",
                    overflow: "hidden",
                    wordWrap: "break-word",
                  }}
                >
                  {repliedMess.msg}
                </h5>
              </div>
            </div>
          </div>
        )}
        <div className={a ? "chat start" : "chat"}>
          {a ? (
            // <img src={url.server + profileImage} alt='profile' className='profile' />
            <div
              className="profile"
              style={{
                backgroundColor: profileImage ? profileImage : "#9a9c9e",
              }}
            >
              {name ? name.charAt(0).toUpperCase() : "X"}
            </div>
          ) : (
            <div className="spacer"></div>
          )}
          <div className="msg">
            {a ? (
              <>
                <h4>
                  <span style={{ textDecoration: banned ? 'line-through' : 'none', color: banned ? 'black' : 'white' }}>
                    {name}
                  </span>
                  <span className="noselect" style={{fontSize: '80%'}}>&nbsp; {date}</span>
                </h4>
              </>
            ) : (
              <></>
            )}
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h5
                style={{
                  maxWidth: "270px",
                  overflow: "hidden",
                  wordWrap: "break-word",
                }}
              >
                {props.msg}
              </h5>
              {type === "file" ? (
                <>
                  {displayable ? (
                    <a
                      href={url.server + filedata.src}
                      download={url.server + filedata.src}
                      className="file-chat-preview"
                    >
                      <img src={url.server + filedata.src} alt="file" />
                    </a>
                  ) : (
                    <div className="file-attachment">
                      <FontAwesomeIcon
                        icon={faFile}
                        style={{
                          margin: "0 2%",
                          fontSize: "1.6rem",
                          color: "rgb(150, 150, 150)",
                          pointerEvents: "none",
                        }}
                      />
                      <div className="filedata">
                        <a href={url.server + filedata.src} target="_blank">
                          {filedata.name}
                        </a>
                        <h6>{(filedata.size / 1000000).toFixed(2)}mb</h6>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
              <div>
                <i
                  class="bi bi-reply-fill"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Cevap ver"
                  style={{ fontSize: "1.2rem", margin: "5px" }}
                  onClick={() => setRepliedMessage()}
                ></i>
                {currentUser.package === "power" && (
                  <i
                    className="bi  bi-list"
                    style={{ fontSize: "1.2rem" }}
                    onClick={() => setProcessModalChat(true)}
                  ></i>
                )}
              </div>
            </div>
          </div>
          {processModalChat && (
            <div className="modal">
              <div
                className="btn-group center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  height: "100%",
                  width: "100%",
                }}
              >
                <h5 style={{ color: "white" }}>
                  --- {name}: {props.msg} ----
                </h5>
                {banned === false ? (
                <button
                  className="btn login noselect warning"
                  type="button"
                  style={{
                    backgroundColor: "orange",
                    color: "white",
                    width: "80%",
                  }}
                  onClick={() => setBlockUserModalChat(false)} // Cancel butonuna basınca modalı kapatır
                >
                  <FontAwesomeIcon
                    icon={faBan}
                    color="white"
                    className="usr-icon"
                    id="usr-icon"
                  />
                  Kullanıcıyı Blokla
                </button>
                    ): (
                    <button
                        className="btn login noselect warning"
                        type="button"
                        style={{
                          backgroundColor: "yellow",
                          color: "black",
                          width: "80%",
                        }}
                        onClick={() => setUnBlockUserModalChat(false)} // Cancel butonuna basınca modalı kapatır
                    >
                      <FontAwesomeIcon
                          icon={faAward}
                          color="black"
                          className="usr-icon"
                          id="usr-icon"
                      />
                      Kullanıcı Blok Kaldır
                    </button>
                )}

                <button
                  className="btn login noselect warning"
                  type="button"
                  style={{
                    backgroundColor: "yellow",
                    color: "black",
                    width: "80%",
                  }}
                  onClick={() => setProcessModalChat(false)} // Cancel butonuna basınca modalı kapatır
                >
                  VAZGEÇ
                </button>
                <button
                  className="btn login noselect error"
                  type="button"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    width: "80%",
                  }}
                  onClick={() => handleDeleteMSGToggle()}
                >
                  <i
                    className="bi bi-trash"
                    style={{
                      fontWeight: "bold", // Kalın font
                      // textShadow: "1px 0 0 currentColor, -1px 0 0 currentColor",
                      // color: "#808080a3",
                    }}
                  ></i>
                  SİL
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
