import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faUnlock,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  selectChannel,
  setChannel,
  setStateChannel,
} from "../features/channelSlice";
import { selectUser } from "../features/userSlice";
import { selectServer } from "../features/serverSlice";
import { setActiceChat, selectActiveChat } from "../features/activeChatSlice";
import { useSelector, useDispatch } from "react-redux";

//import url from "../url.json";
import url from "../features/url";
import { clearQuoteMsg } from "../features/messageReplySlice";
export default function CategoryButton(props) {
  let currentChannel = useSelector(selectChannel);
  let currentUser = useSelector(selectUser);
  let currentServer = useSelector(selectServer);
  let currentActive = useSelector(selectActiveChat);
  let [peer, setPeer] = React.useState([]);
  const [chatLock, setChatLock] = useState(false);
  const [chatPublish, setChatPublish] = useState(false);
  const [processModal, setProcessModal] = useState(false);

  const dispatch = useDispatch();
  let lock = props.lock;
  let name = props.name;
  let channelType = props.type;
  let socket = props.socket;
  let id = props.uid;
  let peerId = props.PeerId;
  let state = props.state;
  let published = props.published;

  function setCurrentChannel() {
    dispatch(
      setChannel({
        channelName: name,
        channelId: id,
        lock: lock,
        published: published,
      })
    );
    setChatLock(lock);
    dispatch(clearQuoteMsg());
  }

  React.useEffect(() => {
    if (channelType === "voice") {
      setTimeout(() => {
        fetch(`${url.server}api/get/active-peers`, {
          method: "POST",
          credentials: "include",
          withCredentials: true,
          headers: {
            //   'Access-Control-Allow-Origin': url.frontend,
            //   'Access-Control-Allow-Credentials': 'true',
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id }),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.error === null) {
              let a = res.data;
              setPeer(a);
            }
          });
      }, 3000);
    }
  }, [state]);
  React.useEffect(() => {
    // console.log("lock");
    // console.log(props.name);
    // console.log(id);
    // console.log(lock);
    setChatLock(props.lock);
  }, [props.lock]);
  React.useEffect(() => {
    socket.on(
      "voice-chat-update-user-list",
      (channelId, data, userId, entered) => {
        if (channelId === id) {
          if (entered) {
            setPeer((old) => [...old, data]);
          } else {
            setTimeout(() => {
              fetch(`${url.server}api/get/active-peers`, {
                method: "POST",
                credentials: "include",
                withCredentials: true,
                headers: {
                  //  'Access-Control-Allow-Origin': url.frontend,
                  //  'Access-Control-Allow-Credentials': 'true',
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: id }),
              })
                .then((response) => response.json())
                .then((res) => {
                  if (res.error === null) {
                    let a = res.data;
                    setPeer(a);
                  }
                });
            }, 3000);
          }
        }
      }
    );
    if (channelType === "voice") {
      fetch(`${url.server}api/get/active-peers`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          //   'Access-Control-Allow-Origin': url.frontend,
          //  'Access-Control-Allow-Credentials': 'true',
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === null) {
            let a = peer.concat(peer, res.data);
            setPeer(a);
            const isFound = a.some((element) => {
              if (element.key === currentUser.id) {
                return true;
              }
            });
            if (isFound) {
              dispatch(
                setActiceChat({
                  id: id,
                  name: name,
                })
              );
              socket.emit(
                "voice-chat-join",
                currentServer.server.id,
                id,
                peerId,
                currentUser.id
              );
            }
          }
        });
      socket.emit("get-active-peers", currentUser.id, id);
    }
  }, []);

  const handleLockToggle = async () => {
    try {
      let response;
      console.log("chatLock", chatLock);
      if (chatLock) {
        response = await fetch(`${url.server}api/channel/unlock`, {
          method: "POST",
          credentials: "include",
          withCredentials: true,
          headers: {
            //    'Access-Control-Allow-Origin': url.frontend,
            //    'Access-Control-Allow-Credentials': 'true',
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id }),
        });

        if (response.ok) {
          setChatLock(false);
          socket.emit("channel-lock", "channellist-" + id + "-unlock");
        }
      } else {
        response = await fetch(`${url.server}api/channel/lock`, {
          method: "POST",
          credentials: "include",
          withCredentials: true,
          headers: {
            //    'Access-Control-Allow-Origin': url.frontend,
            //    'Access-Control-Allow-Credentials': 'true',
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id }),
        });

        // console.log("response.ok lock");
        // console.log(response.ok);

        if (response.ok) {
          setChatLock(true);
          socket.emit("channel-lock", "channellist-" + id + "-lock");
        }
      }
    } catch (error) {
      console.error("Error toggling lock:", error);
    }
  };
  const handlePublishToggle = async () => {
    console.log("handlePublishToggle");
    console.log(currentChannel);
    try {
      let response;

      response = await fetch(`${url.server}api/publish/channel`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          //    'Access-Control-Allow-Origin': url.frontend,
          //    'Access-Control-Allow-Credentials': 'true',
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          channelId: id,
          published: !published,
        }),
      });
      console.log(response);
      if (response.ok) {
        socket.emit("channel-lock", "channellist-" + id + "-publish");
        setProcessModal(false);
        setChatPublish(!published);
      }
    } catch (error) {
      console.error("Error toggling lock:", error);
    }
  };
  const handleDeleteToggle = async () => {
    try {
      let response;

      response = await fetch(`${url.server}api/channel/delete`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          //    'Access-Control-Allow-Origin': url.frontend,
          //    'Access-Control-Allow-Credentials': 'true',
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ channelId: id, user_id: currentUser.id }),
      });
      console.log(response);
      if (response.ok) {
        socket.emit("channel-lock", "channellist-" + id + "-delete");
        dispatch(setStateChannel(id));
      }
    } catch (error) {
      console.error("Error toggling lock:", error);
    }
  };

  function joinVoice(id) {
    if (currentActive.active == null) {
      socket.emit(
        "voice-chat-join",
        currentServer.server.id,
        id,
        peerId,
        currentUser.id
      );
      let data = {
        image: currentUser.profile,
        displayName: currentUser.name,
        key: currentUser.id,
      };
      const isFound = peer.some((element) => {
        if (element.key === currentUser.id) {
          return true;
        }
      });

      if (!isFound) {
        setPeer((old) => [...old, data]);
        dispatch(
          setActiceChat({
            id: id,
            name: name,
          })
        );
      }
    } else {
      alert("disconnect other voice channel");
    }
  }
  useEffect(() => {
    setChatPublish(published);
  }, [published]);
  return (
    <>
      {channelType === "text" ? (
        <div
          className={
            id === currentChannel.channel.channelId
              ? "channel-select-btn noselect channel-active"
              : "channel-select-btn noselect"
          }
          onClick={setCurrentChannel}
          style={{
            display: "flex",
            alignItems: "center",
            wordBreak: "break-all",
          }}
        >
          {currentUser.package === "power" ? (
            <span onClick={handleLockToggle} style={{ cursor: "pointer" }}>
              {chatLock ? (
                <FontAwesomeIcon
                  icon={faLock}
                  className="icon"
                  style={{ marginRight: "8px" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faUnlock}
                  className="icon"
                  style={{ marginRight: "8px" }}
                />
              )}
            </span>
          ) : currentUser.package === "default" ? (
            <span style={{ cursor: "pointer" }}>
              {lock && chatLock ? (
                <FontAwesomeIcon
                  icon={faLock}
                  className="icon"
                  style={{ marginRight: "8px" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faUnlock}
                  className="icon"
                  style={{ marginRight: "8px" }}
                />
              )}
            </span>
          ) : null}{" "}
          {name}{" "}
          <small style={{ fontSize: "x-small" }}>
            {" "}
            {chatPublish && currentUser.package === "power" && "(yayında)"}
            {/* {!published && currentUser.package === "power" && "(hazırlanıyor)"} */}
          </small>
          {currentUser.package === "power" && (
            <span
              onClick={() => setProcessModal(true)}
              // onClick={handleDeleteToggle}
              style={{ cursor: "pointer", marginLeft: "auto" }}
            >
              <i className="bi bi-list"></i>
            </span>
          )}
        </div>
      ) : (
        <>
          {/*<div className="channel-select-btn noselect" onClick={() => joinVoice(id)}><FontAwesomeIcon icon={faVolumeUp} className="icon" />{name}</div>
                    {peer.map((p) => (
                        <div key={p.key} className='voice-chat-peers noselect'>
                            <img src={url.server + p.image} />
                            <h5>{p.displayName}</h5>
                        </div>
                    ))}*/}
        </>
      )}
      {processModal && (
        <div className="modal">
          <div
            className="btn-group center"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              height: "100%",
              width: "100%",
            }}
          >
            <h5 style={{ color: "white" }}>--- {name} ----</h5>
            <button
              className="btn login noselect warning"
              type="button"
              style={{
                backgroundColor: "yellow",
                color: "black",
                width: "80%",
              }}
              onClick={() => setProcessModal(false)} // Cancel butonuna basınca modalı kapatır
            >
              VAZGEÇ
            </button>
            <button
              className="btn login noselect error"
              type="button"
              style={{
                backgroundColor: "red",
                color: "white",
                width: "80%",
              }}
              onClick={() => handleDeleteToggle()}
            >
              <i
                className="bi bi-trash"
                style={{
                  fontWeight: "bold", // Kalın font
                  // textShadow: "1px 0 0 currentColor, -1px 0 0 currentColor",
                  // color: "#808080a3",
                }}
              ></i>
              SİL
            </button>
            <button
              className="btn login noselect success"
              style={{
                backgroundColor: "green",
                color: "white",
                width: "80%",
              }}
              onClick={() => handlePublishToggle()}
            >
              <i
                className={`bi ${
                  published ? "bi-bookmark-dash" : "bi-bookmark-check"
                }`}
                style={{ fontSize: "1.2rem" }}
              ></i>
              {published ? "YAYINDAN KALDIR" : "YAYINLA"}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
