import { createSlice } from "@reduxjs/toolkit";

export const channelSlice = createSlice({
  name: "channel",
  initialState: {
    channel: { channelName: "create or join your first server" },
    stateChannel: "asa",
  },
  reducers: {
    setChannel: (state, action) => {
      state.channel = action.payload;
    },
    setStateChannel: (state, action) => {
      console.log("buraya girdi");
      console.log(action.payload);
      state.stateChannel = action.payload;
    },
  },
});
export const { setChannel, setStateChannel } = channelSlice.actions;
export const selectChannel = (state) => state.channel;
export const selectStateChannel = (state) => state.channel.stateChannel;

export default channelSlice.reducer;
