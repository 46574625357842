import React from 'react'

export default function Loading() {
    return (
        <div className='loading'>
            <h1>
                Krallar Forum
            </h1>
            <h3>Loading...</h3>
        </div>
    )
}
