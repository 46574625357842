import React from "react";
//import url from "../url.json";
import url from "../features/url";
export default function MemberName(props) {
  let Name = props.name;
  let profile = props.profile;
  return (
    <div className="member">
      {/* <img src={url.server + profile} alt={Name} />
       */}
      <div
        className="profile-avatar"
        style={{
          backgroundColor: profile ? profile : "#9a9c9e",
        }}
      >
        {Name ? Name.charAt(0).toUpperCase() : "X"}
      </div>
      <h4>{Name}</h4>
    </div>
  );
}
