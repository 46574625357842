import { useState } from "react";
import apiRequest from "../features/apiRequest";

const NotificationList = ({ initialNotifications }) => {
    const [notifications, setNotifications] = useState(initialNotifications);

    const handleRemoveNotification = async (notifId) => {
        try {
            // REST isteği gönder
            await apiRequest("notification/readed", "POST", { notif_id: notifId })

            setNotifications((prev) =>
                prev.filter((notif) => notif.notif_id !== notifId)
            );
        } catch (error) {
            console.error("Bildirim kapatılamadı:", error);
        }
    };

    return (
        <div className="notification-header">
            <h4>Bildirimler</h4>
            <ul className="notification-list">
                {notifications.map((notif) => (
                    <li key={notif.notif_id} className="notification-item">
                        <div className="notification-content">
                            <h5>{notif.details.title}</h5>
                            <p>{notif.details.description}</p>
                            <small>
                                Yayınlanma Tarihi:{" "}
                                {new Date(notif.details.publishedAt).toLocaleString()}
                            </small>
                        </div>
                        <button
                            className="close-btn"
                            onClick={() => handleRemoveNotification(notif.notif_id)}
                        >
                            &times;
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NotificationList;
