import React, { useEffect, useState } from "react";
//import ImojiButton from "./ImojiButton";
import ChatDisplay from "./ChatDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAward, faBan, faBell, faPlusCircle, faUnlock} from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { selectChannel } from "../features/channelSlice";
import { selectUser } from "../features/userSlice";
import { selectServer } from "../features/serverSlice";
import { useDispatch, useSelector } from "react-redux";

import { selectMenu, setMenu } from "../features/mobileSlice";
//import url from "../url.json";
import url from "../features/url";
import apiRequest from "../features/apiRequest";
import ImojiButton from "./ImojiButton";
import { selectmesDel } from "../features/messageDelSlice";
import {
  clearQuoteMsg,
  selectQuotedMessage,
} from "../features/messageReplySlice";

export default function Chat(props) {
  let chatContainer = React.createRef();
  const scrollToMyRef = () => {
    const scroll =
      chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
    chatContainer.current.scrollTo(0, scroll);
  };
  var socket = props.socket;
  const dispatch = useDispatch();
  let currentChannel = useSelector(selectChannel);
  let selectmesDel_ = useSelector(selectmesDel);
  let currentMenuState = useSelector(selectMenu);
  let currentUser = useSelector(selectUser);
  let currentServer = useSelector(selectServer);
  let [message, setMessage] = useState("");

  let [notifDescription, setNotifDescription] = useState("");
  let [notifReceivers, setNotifReceivers] = useState("");
  let [notifTitle, setNotifTitle] = useState("");
  let [notifUnPublishedAt, setNotifUnPublishedAt] = useState("");


  let [delMessageID, setdelMessageID] = useState("");
  let [messageList, setMessageList] = useState([]);
  let [previousMessageList, setPreviousMessageList] = useState([]);
  let [response, setResponse] = useState([]);
  let [countId, setCountId] = useState(0);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const replyMessage = useSelector(selectQuotedMessage);
  let [reply, setReply] = useState({});
  const [notificationModal, setNotificationModal] = useState(false);
  const handleEmojiSelect = (emoji) => {
    // Append the selected emoji to the message
    setMessage((prevMessage) => prevMessage + emoji);
  };
  const handleEmojiSelectForNotif = (emoji) => {
    // Append the selected emoji to the message
    setNotifDescription((prevMessage) => prevMessage + emoji);
  };
  useEffect(() => {
    socket.on("msg", (a) => {
      setResponse(a);
    });
  }, []);
  useEffect(() => {
    // setMessageList((prevList) =>
    //   prevList.filter((message) => message[8] !== selectmesDel_)
    // );
    setMessageList((prevList) => {
      const newList = prevList.filter(
        (message) => message[8] !== selectmesDel_
      );

      // Eğer `selectmesDel_` bulunamadıysa bir fonksiyon çalıştır
      if (newList.length === prevList.length) {
        getChat(); // Bulunamadığında çalışacak fonksiyon
      }

      return newList;
    });
  }, [selectmesDel_]);
  useEffect(() => {
    if (response[3] === currentChannel.channel.channelId) {
      setMessageList((b) => [...b, response]);
    }
  }, [response]);
  useEffect(() => {
    console.log(messageList);
    console.log(previousMessageList);
  }, [messageList]);
  useEffect(() => {
    // socket.emit("join-room", currentChannel.channel.channelId);
    console.log("currentChannel");
    console.log(currentChannel.channel.channelId);
    socket.on("msg-deleted", ({ msgId }) => {
      if (msgId !== delMessageID) {
        console.log("Message deleted with ID:", msgId);
        // TODO::: Remove the message from your UI state
        // setMessages((prevMessages) =>
        //   prevMessages.filter((msg) => msg.id !== msgId)
        // );
        setdelMessageID(msgId);
        getChat();
      }
    });
    getChat();
  }, [currentChannel.channel]);
  function getChat() {
    const requestOptions = {
      method: "POST",
      headers: {
        // "Access-Control-Allow-Origin": url.frontend,
        // "Access-Control-Allow-Credentials": "true",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ channelId: currentChannel.channel.channelId }),
    };
    fetch(`${url.server}api/get/chat`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error === null) {
          setMessageList([]);
          setPreviousMessageList(data.chat);
        }
      });
    socket.emit("join-room", currentChannel.channel.channelId);
  }
  useEffect(() => {
    scrollToMyRef();
  }, [messageList, previousMessageList]);
  function handleSubmit(e) {
    e.preventDefault();
    console.log('currentUser for MSG => ')
    console.log(currentUser)
    const dt = Date.now().toString();
    console.log(dt);
    if (message !== "" && !selectedFile) {
      //TODO:: Emitte replied edilmiş mesaj eklenecek
      socket.emit(
        "send-msg",
        message,
        currentChannel.channel.channelId,
        currentUser.id,
        currentServer.server.serverId,
        "msglist" +
          currentChannel.channel.channelId +
          "-" +
          countId +
          "-" +
          dt.toString() +
          "-" +
          currentUser.id,
        replyMessage
      );
      //TODO:: mevcut liste replied edilmiş mesaj eklenecek
      setMessageList((b) => [
        ...b,
        [
          message,
          currentUser.name,
          currentUser.profile,
          currentChannel.channel.channelIdx,
          Date.now(),
          currentUser.id,
          "text",
          "",
          "msglist" +
            currentChannel.channel.channelId +
            "-" +
            countId +
            "-" +
            dt.toString() +
            "-" +
            currentUser.id,
          replyMessage,
        ],
      ]);
      setCountId(countId + 1);
      setMessage("");
      dispatch(clearQuoteMsg());
    }
    if (selectedFile) {
      console.log(selectedFile);
      let selectedFilesrc;
      var data = new FormData();
      data.append("file", selectedFile);
      data.append("filename", selectedFile.name);
      fetch(`${url.server}api/upload-file`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          // "Access-Control-Allow-Origin": url.frontend,
          // "Access-Control-Allow-Credentials": "true",
          // 'Content-Type': 'multipart/form-data'
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: data,
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === "Uploaded") {
            selectedFilesrc = res.data;
            socket.emit(
              "message-with-file",
              selectedFile.name,
              selectedFile.size,
              selectedFilesrc,
              message,
              currentChannel.channel.channelId,
              currentUser.id,
              currentServer.server.serverId
            );
            setMessageList((b) => [
              ...b,
              [
                message,
                currentUser.name,
                currentUser.profile,
                currentChannel.channel.channelId,
                Date.now(),
                currentUser.id,
                "file",
                {
                  name: selectedFile.name,
                  size: selectedFile.size,
                  src: selectedFilesrc,
                },
              ],
            ]);
          }
        });
      setMessage("");
      setSelectedFile("");
    }
  }
  const validateNotifForm = (data) => {
    const errors = {};

    // Alanların boş olup olmadığını kontrol et
    Object.keys(data).forEach((key) => {
      if (!data[key] && key !== "unPublishedAt") {
        errors[key] = `${key} alanı boş olamaz!`;
      }
    });

    return errors;
  };
  async function handleNotifSubmit(e) {
    e.preventDefault();
    console.log('currentUser for Notif => ')
    console.log(currentUser)
    const dt = Date.now().toString();
    console.log(dt);

    const notifSetData = {
      // 'type'
      'receiverType': notifReceivers,
      //'sender'
      'title': notifTitle,
      'description': notifDescription,
      //'publishedAt'
      'unPublishedAt': notifUnPublishedAt
    }

    console.log('notifSetData')
    console.log(notifSetData)

    const errors = validateNotifForm(notifSetData)


    if (Object.keys(errors).length > 0) {
      console.log("Form hataları:", errors);
      alert(Object.values(errors).join("\n")); // Hataları bir mesaj kutusunda göster
      return;
    }

    const setNotif = await apiRequest('api/set/notification', "POST", notifSetData)

    if(setNotif.error === "null"){
      alert(setNotif.user_count + ' kişiye bildirim gönderildi!')
      setNotificationModal(false)
    }

  }
  let [selectedFile, setSelectedFile] = React.useState("");
  function FileChosen(evnt) {
    if (evnt.target.files[0]) {
      setSelectedFile(evnt.target.files[0]);
      document.querySelector(".cinput").focus();
    }
  }

  const handleMenuToggle = async () => {
    console.log("currentMenuState");
    console.log(currentMenuState);
    dispatch(setMenu({ active: !currentMenuState }));
    // Menü aktif ise kapat, kapalı ise aç.
    // dispatch(toggleMenu());
  };
  useEffect(() => {
    console.log("currentMenuState");
    console.log(currentMenuState);
  }, [currentMenuState]);
  const closeRepliedMessage = async () => {
    dispatch(clearQuoteMsg());
  };
  React.useEffect(() => {
    console.log("Reply message updated:", replyMessage);
  }, [replyMessage]);

  function fornattedDate(dateString) {
    //return dateString;
    const date = new Date(dateString);
    return date.toLocaleString("tr-TR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      //second: '2-digit',
      hour12: false,
    });
  }
  return (
    <>
      <div className="chat-section">
        <div className="chat-header noselect">
          <div className="chat-title">
          <h2>#</h2> <h4>{currentChannel.channel.channelName}</h4>
          {/* <IconButton
            aria-label="toggle-menu"
            color="primary"
            class="mobile-menu"
            style={{
              marginLeft: "auto",
              backgroundColor: "transparent",
              color: "grey",
              border: "none",
            }}
            onClick={() => handleMenuToggle()}
          >
            <CompareArrowsIcon />
          </IconButton> */}
          <button
            aria-label="toggle-menu"
            className="btn mobile-menu"
            style={{
              marginLeft: "auto",
              backgroundColor: "transparent",
              color: "grey",
              border: "none",
              fontSize: "16px",
            }}
            onClick={() => handleMenuToggle()}
          >
            <i
              className="bi bi-arrow-left-right"
              style={{
                fontWeight: "bold", // Kalın font
                textShadow: "1px 0 0 currentColor, -1px 0 0 currentColor",
              }}
            ></i>{" "}
            {/* Bootstrap ikonu */}
          </button>
          </div>

          {currentUser.package === "power" && (
            <FontAwesomeIcon
                icon={faBell}
                className="icon"
                style={{ marginRight: "8px" }}
                onClick={() => setNotificationModal(true)}
            />
              )}


        </div>
        <div className="chat-display" ref={chatContainer}>
          {previousMessageList.map((message, ind) => (
            <ChatDisplay
              lastmessage={
                ind === 0
                  ? 0
                  : {
                      id: previousMessageList[ind - 1]["senderId"],
                      date: previousMessageList[ind - 1]["time"],
                    }
              }
              key={message._id}
              id_={message._id}
              senderId={message.senderId}
              from={message.by}
              profileImage={message.senderProfile}
              date={message.time}
              msg={message.message}
              current={message["senderId"]}
              type={message.type}
              filedata={message.filedata}
              socket={props.socket}
              repliedMess={message.repliedMessId}
              banned={message.banned}
            ></ChatDisplay>
          ))}
          {messageList.map((message, ind) => (
            <ChatDisplay
              lastmessage={
                ind === 0
                  ? previousMessageList[previousMessageList - 1]
                    ? {
                        id: previousMessageList[previousMessageList - 1][
                          "senderId"
                        ],
                        date: previousMessageList[previousMessageList - 1][
                          "time"
                        ],
                      }
                    : { id: 0, date: "" }
                  : {
                      id: messageList[ind - 1][5],
                      date: messageList[ind - 1][4],
                    }
              }
              key={ind}
              from={message[1]}
              profileImage={message[2]}
              date={message[4]}
              msg={message[0]}
              current={message[5]}
              type={message[6]}
              filedata={message[7]}
              id_={`${message[8]}`}
              senderId={message[9]}
              socket={props.socket}
              repliedMess={message[9]}
            ></ChatDisplay>
          ))}
        </div>
        {replyMessage.msg && (
          <div className={"chat replied"}>
            <div
              className="profile"
              style={{
                backgroundColor: "#9a9c9e",
              }}
            >
              {replyMessage.name
                ? replyMessage.name.charAt(0).toUpperCase()
                : "X"}
            </div>
            <div
              className="msg"
              style={{
                width: "100%",
              }}
            >
              <h4>
                {replyMessage.name}{" "}
                <span className="noselect">
                  {fornattedDate(replyMessage.date)}
                </span>
              </h4>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5
                  style={{
                    maxWidth: "270px",
                    overflow: "hidden",
                    wordWrap: "break-word",
                  }}
                >
                  {replyMessage.msg}
                </h5>
                <i
                  className="bi bi-x"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Vazgeç"
                  onClick={() => closeRepliedMessage()}
                ></i>
              </div>
            </div>
          </div>
        )}
        <div className="chat-input">
          {selectedFile !== "" ? (
            <div className="file-name-display">
              <FontAwesomeIcon
                icon={faFile}
                style={{
                  margin: "0 2%",
                  fontSize: "1.3rem",
                  color: "rgb(150, 150, 150)",
                  pointerEvents: "none",
                }}
              />
              {selectedFile ? selectedFile.name : ""}
              <button onClick={() => setSelectedFile("")}>X</button>
            </div>
          ) : null}
          <div className="chat-input-btns">
            <form onSubmit={handleSubmit}>
              <input
                type="file"
                style={{ position: "absolute", opacity: "0", zIndex: "0" }}
                onChange={(e) => FileChosen(e)}
              />
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{
                  margin: "0 2%",
                  cursor: "pointer",
                  fontSize: "1.3rem",
                  color: "rgb(150, 150, 150)",
                  pointerEvents: "none",
                }}
              />
              <input
                type="text"
                style={{ zIndex: "1" }}
                className="cinput"
                placeholder={
                  currentUser.banned === false &&
                  currentChannel.channel.lock === true &&
                  currentUser.package === "default"
                    ? "Bu kanala mesaj gönderemezsin."
                    : `#${currentChannel.channel.channelName}`
                }
                value={message}
                onChange={(e) => {
                  if (
                    currentChannel.channel.lock &&
                    currentUser.package !== "power"
                  ) {
                    // Eğer kullanıcı yetkisizse mesajı değiştirmeyelim
                    return;
                  }
                  setMessage(e.target.value);
                }}
                disabled={
                  currentChannel.channel.lock && currentUser.package !== "power"
                } // Kilitliyse input disabled olacak
              />
            </form>

            {/* <div className="gif-icon noselect">
              <h5>GIF</h5>
            </div> */}
            {/* <ImojiButton onEmojiSelect={handleEmojiSelect} /> */}
            <ImojiButton onEmojiSelect={handleEmojiSelect} id="chat" />
            <button
              onClick={handleSubmit}
              style={{
                marginLeft: "auto",
                backgroundColor: "transparent",
                color: "#e9e8e8",
                border: "none",
                fontSize: "16px",
              }}
            >
              <i
                class="bi bi-send"
                style={{
                  textShadow: "#e9e8e8 0px 0px 2px",
                  fontSize: "1.2em",
                }}
              ></i>
            </button>
          </div>
          {notificationModal && (
              <div className="modal">
                <div
                    className="btn-group center"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                      height: "100%",
                      width: "100%",
                    }}
                >
                  <h5 style={{ color: "white" }}>
                    Yeni Bildirim Tanımla
                  </h5>

                  <form onSubmit={handleNotifSubmit}>
                    <label htmlFor="receivers">Alıcıları Seçin</label>
                    <select
                        id="receivers"
                        name="receivers"
                        value={notifReceivers}
                        onChange={(e) => setNotifReceivers(e.target.value)}
                    >
                      <option value="">---</option>
                      <option value="All">Herkes</option>
                      <option value="Admins">Adminler</option>
                      <option value="ServerUsers">Kullanıcılar</option>
                    </select>

                    <label htmlFor="title">Başlık</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={notifTitle}
                        onChange={(e) => setNotifTitle(e.target.value)}
                    />

                    <label htmlFor="description">İçerik</label>
                    <input
                        type="text"
                        id="description"
                        name="description"
                        value={notifDescription}
                        onChange={(e) => setNotifDescription(e.target.value)}
                    />

                    <ImojiButton onEmojiSelect={handleEmojiSelectForNotif} id="chat" />

                    <label htmlFor="unPublishedAt">Son Yayın Tarihi</label>
                    <input
                        type="datetime-local"
                        id="unPublishedAt"
                        name="unPublishedAt"
                        value={notifUnPublishedAt}
                        onChange={(e) => setNotifUnPublishedAt(e.target.value)}
                    />

                    <button type="submit">Gönder</button>
                  </form>

                  <button
                      className="btn login noselect warning"
                      type="button"
                      style={{
                        backgroundColor: "yellow",
                        color: "black",
                        width: "80%",
                      }}
                      onClick={() => setNotificationModal(false)} // Cancel butonuna basınca modalı kapatır
                  >
                    VAZGEÇ
                  </button>

                </div>
              </div>
          )}
        </div>
      </div>
    </>
  );
}
