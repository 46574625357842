import React from "react";
import MemberName from "./MemberName";
import { selectServer } from "../features/serverSlice";
import { useDispatch, useSelector } from "react-redux";
//import url from "../url.json";
import { selectMenu, setMenu } from "../features/mobileSlice";
import url from '../features/url';
import {selectUser} from "../features/userSlice";


export default function ServerMembers(props) {
  const dispatch = useDispatch();
  let currentMenuState = useSelector(selectMenu);
  let currentServer = useSelector(selectServer);
  let [members, setMembers] = React.useState([]);
  var socket = props.socket;
  let currentUser = useSelector(selectUser);
  function getmembers() {
    if (currentServer.server.name !== "loading") {
      fetch(`${url.server}api/get/member`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": url.frontend,
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: currentServer.server.serverId,
          user_id: currentUser.id,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === null) {
            console.log('res.users')
            console.log(res.users)
            setMembers(res.users);
          }
        });
    }
  }
  React.useEffect(() => {
    socket.on("member-joined", (id, u) => {
      console.log("hehe");
      fetch(`${url.server}api/get/member`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": url.frontend,
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === null) {
            setMembers(res.users);
          }
        });
    });
  });
  // eslint-disable-next-line
  React.useEffect(() => {
    getmembers();
  }, [currentServer.server]);
  const handleMenuToggle = async () => {
    console.log("currentMenuState");
    console.log(currentMenuState);
    dispatch(setMenu({ active: !currentMenuState }));
    // Menü aktif ise kapat, kapalı ise aç.
    // dispatch(toggleMenu());
  };
  return (
    <div
      className={`server-members ${
        !currentMenuState
          ? "server-members-mobile"
          : "server-members-open-mobile"
      }`}
      onClick={() => handleMenuToggle()}
    >
      <div className="chat-header noselect">
        {" "}
        <button
          aria-label="toggle-menu"
          className="btn mobile-menu"
          style={{
            marginLeft: "auto",
            backgroundColor: "transparent",
            color: "grey",
            border: "none",
            fontSize: "16px",
          }}
        >
          <i
            className="bi bi-arrow-left-right"
            style={{
              fontWeight: "bold", // Kalın font
              textShadow: "1px 0 0 currentColor, -1px 0 0 currentColor",
            }}
          ></i>{" "}
          {/* Bootstrap ikonu */}
        </button>
        <h3>Members</h3>
      </div>
      <div className="chat-member">
        {members.map((member) => (
          <MemberName
            key={member.id}
            name={member.name}
            profile={member.profile}
          />
        ))}
      </div>
    </div>
  );
}
