import React from "react";
import MemberName from "./MemberName";
import { selectServer } from "../features/serverSlice";
import { useDispatch, useSelector } from "react-redux";
//import url from "../url.json";
import { selectMenu, setMenu } from "../features/mobileSlice";
import url from "../features/url";
import { selectUser } from "../features/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import apiRequest from "../features/apiRequest";

export default function ServerMembers(props) {
  const dispatch = useDispatch();
  let currentMenuState = useSelector(selectMenu);
  let currentServer = useSelector(selectServer);
  let [members, setMembers] = React.useState([]);
  var socket = props.socket;
  let currentUser = useSelector(selectUser);
  let [userGrantModal, setUserGrantModal] = React.useState(false);
  let [userSearch, setUserSearch] = React.useState("");
  let [users, setUsers] = React.useState([]);

  function getmembers() {
    if (currentServer.server.name !== "loading") {
      fetch(`${url.server}api/get/member`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": url.frontend,
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: currentServer.server.serverId,
          user_id: currentUser.id,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === null) {
            console.log("res.users");
            console.log(res.users);
            setMembers(res.users);
          }
        });
    }
  }
  React.useEffect(() => {
    socket.on("member-joined", (id, u) => {
      console.log("hehe");
      fetch(`${url.server}api/get/member`, {
        method: "POST",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": url.frontend,
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.error === null) {
            setMembers(res.users);
          }
        });
    });
  });
  // eslint-disable-next-line
  React.useEffect(() => {
    getmembers();
  }, [currentServer.server]);
  const handleMenuToggle = async () => {
    console.log("currentMenuState");
    console.log(currentMenuState);
    dispatch(setMenu({ active: !currentMenuState }));
    // Menü aktif ise kapat, kapalı ise aç.
    // dispatch(toggleMenu());
  };

  const openUserGrantModal = (modalState) => {
    setUserGrantModal(modalState);
  };

  const searchUser = async () => {
    try {
      console.log(userSearch);
      const res = await apiRequest("api/search/user", "POST", {
        search: userSearch,
      });

      if (res.result) {
        setUsers(res.result); // Gelen kullanıcı listesini state'e kaydet
      } else {
        console.error("No users found");
        setUsers([]);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleUserPermission = (user) => {
    console.log(`${user.displayName} yetkili yapılıyor.`);
    console.log(user);
    apiRequest("api/set/permission", "POST", {
      id: currentServer.server.serverId,
      user_id: user._id,
      permission: user.package === "default" ? "power" : "default",
    });
  };

  return (
    <div
      className={`server-members ${
        !currentMenuState
          ? "server-members-mobile"
          : "server-members-open-mobile"
      }`}
      onClick={() => handleMenuToggle()}
    >
      <div className="chat-header noselect">
        {" "}
        <button
          aria-label="toggle-menu"
          className="btn mobile-menu"
          style={{
            marginLeft: "auto",
            backgroundColor: "transparent",
            color: "grey",
            border: "none",
            fontSize: "16px",
          }}
        >
          <i
            className="bi bi-arrow-left-right"
            style={{
              fontWeight: "bold", // Kalın font
              textShadow: "1px 0 0 currentColor, -1px 0 0 currentColor",
            }}
          ></i>{" "}
          {/* Bootstrap ikonu */}
        </button>
        <h3>Members</h3>
        {currentUser.package === "power" && (
          <FontAwesomeIcon
            icon={faUserGraduate}
            className="icon"
            style={{ marginRight: "8px", zIndex: 99999 }}
            onClick={() => openUserGrantModal(true)}
          />
        )}
      </div>
      <div className="chat-member">
        {members.map((member) => (
          <MemberName
            key={member.id}
            name={member.name}
            profile={member.profile}
          />
        ))}
      </div>{" "}
      {userGrantModal && (
        <div className="modal">
          <div className="modal-content">
            <div style={{textAlign: 'right', color: 'white'}}>
              <a href="#" style={{color: 'white'}} onClick={() => setUserGrantModal(false)}>X</a>
            </div>
            <h5 className="modal-title">Kullanıcı Yetkilerini Değiştir</h5>

            <div className="form-group">
              <label htmlFor="userSearch">Kullanıcı Ara</label>
              <input
                type="text"
                id="userSearch"
                name="userSearch"
                value={userSearch}
                onChange={(e) => setUserSearch(e.target.value)}
              />

              <i
                className="bi bi-search"
                onClick={() => searchUser()}
                style={{
                  fontWeight: "bold",
                  textShadow: "1px 0 0 currentColor, -1px 0 0 currentColor",
                  color: "grey",
                }}
              ></i>
            </div>

            <table>
              <thead>
                <tr>
                  <th>İsim</th>
                  <th>Email</th>
                  <th>Paket</th>
                  <th>Yetki</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user._id}>
                    <td>{user.displayName}</td>
                    <td>{user.email}</td>
                    <td>{user.package}</td>
                    <td>
                      <button onClick={() => handleUserPermission(user)}>
                        {user.package === "default"
                          ? "Yetkili Yap"
                          : "Yetkiyi Düşür"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
